import React, { useState, useEffect } from 'react'
import Layout from "../components/share/layout"
import { navigate } from '@reach/router';
import img1 from '../images/cys/img1.jpeg'
import img2 from '../images/cys/img2.jpeg'
import img3 from '../images/cys/img3.jpg'
import img4 from '../images/cys/img4.jpg'
import img5 from '../images/cys/img5.jpg'
import img6 from '../images/cys/img6.jpg'
import img7 from '../images/cys/img7.jpg'
import img8 from '../images/cys/img8.jpg'
import img9 from '../images/cys/img9.jpg'
import img10 from '../images/cys/img10.jpg'
import img11 from '../images/cys/img11.jpg'
import img12 from '../images/cys/img12.jpg'
import img13 from '../images/cys/img13.jpg'
import img14 from '../images/cys/img14.jpg'
import img15 from '../images/cys/img15.jpg'
import img16 from '../images/cys/img16.jpg'
import img17 from '../images/cys/img17.jpg'
import img18 from '../images/cys/img18.jpg'
import img19 from '../images/cys/img19.jpg'
import img20 from '../images/cys/img20.jpg'
import img21 from '../images/cys/img21.jpg'
import img22 from '../images/cys/img22.jpg'
import img23 from '../images/cys/img23.jpg'
import img24 from '../images/cys/img24.jpeg'
import img25 from '../images/cys/img25.jpg'
import img26 from '../images/cys/img26.jpg'
import img27 from '../images/cys/img27.jpg'
/* import img28 from '../images/cys/img28.jpg' */
import img29 from '../images/cys/img29.jpg'
import img30 from '../images/cys/img30.jpg'
import img31 from '../images/cys/img31.jpg'
import img32 from '../images/cys/img32.jpg'
import img33 from '../images/cys/img33.jpg'
import img34 from '../images/cys/img34.jpg'
import img35 from '../images/cys/img35.jpg'
import img36 from '../images/cys/img36.jpg'
import img37 from '../images/cys/img37.jpg'
import img38 from '../images/cys/img38.jpg'
import img39 from '../images/cys/img39.jpg'
import img40 from '../images/cys/img40.jpg'
import img41 from '../images/cys/img41.jpg'
import img42 from '../images/cys/img42.jpg'
import img43 from '../images/cys/img43.jpg'
import img44 from '../images/cys/img44.jpg'
import img45 from '../images/cys/img45.jpg'
import img46 from '../images/cys/img46.jpg'
import img47 from '../images/cys/img47.jpg'
import img48 from '../images/cys/img48.jpg'
import img49 from '../images/cys/img49.jpg'
import img50 from '../images/cys/img50.jpg'
import img51 from '../images/cys/img51.jpg'
import img52 from '../images/cys/img52.jpg'

import Background1 from '../images/cys/backgrounds/1.jpg'
import Background2 from '../images/cys/backgrounds/Mesadetrabajo1copia5.jpg'
import Background6 from '../images/cys/backgrounds/Mesadetrabajo1copia18.jpg'
import Background6Responsive from '../images/cys/backgrounds/Mesadetrabajo74copia12Responsive.jpg'
import Background11 from '../images/cys/backgrounds/1copia3.jpg'
import Background14 from '../images/cys/backgrounds/1copia6.jpg'
import Background8 from '../images/cys/backgrounds/1copia2.jpg'

import BackgroundComplete1 from '../images/cys/Completos/fondo1.jpg'
import BackgroundComplete2 from '../images/cys/Completos/fondo2.jpg'
import BackgroundComplete3 from '../images/cys/Completos/fondo3.jpg'
import BackgroundComplete4 from '../images/cys/Completos/fondo4.jpg'
import BackgroundComplete5 from '../images/cys/Completos/fondo5.jpg'

import Art1 from '../images/cys/arts/1S.jpg'
import Art2 from '../images/cys/arts/2S.jpg'
import Art3 from '../images/cys/arts/3S.jpg'
import Art4 from '../images/cys/arts/4S.jpg'
import Art5 from '../images/cys/arts/5S.jpg'
import Art6 from '../images/cys/arts/6S.jpg'
import Art7 from '../images/cys/arts/7S.jpg'
import Art8 from '../images/cys/arts/8S.jpg'
import Art9 from '../images/cys/arts/9S.jpg'
import Art10 from '../images/cys/arts/10S.jpg'
import Art11 from '../images/cys/arts/11S.jpg'
import Art12 from '../images/cys/arts/12S.jpg'
import Art13 from '../images/cys/arts/13S.jpg'
import Art14 from '../images/cys/arts/14S.jpg'
import Art15 from '../images/cys/arts/15S.jpg'
import Art16 from '../images/cys/arts/16S.jpg'
import Art17 from '../images/cys/arts/17S.jpg'
import Art18 from '../images/cys/arts/18S.jpg'
import Art19 from '../images/cys/arts/19S.jpg'
import Art20 from '../images/cys/arts/20S.jpg'
import Art21 from '../images/cys/arts/21S.jpg'
import Art22 from '../images/cys/arts/22S.jpg'
import Art23 from '../images/cys/arts/23S.jpg'
import Art24 from '../images/cys/arts/24S.jpg'
import Art25 from '../images/cys/arts/25S.jpg'
import Art26 from '../images/cys/arts/26S.jpg'
import Art27 from '../images/cys/arts/27S.jpg'
import Art28 from '../images/cys/arts/28S.jpg'
import Art29 from '../images/cys/arts/29S.jpg'
import Art30 from '../images/cys/arts/30S.jpg'
import Art31 from '../images/cys/arts/31S.jpg'
import Art32 from '../images/cys/arts/32S.jpg'
import Art33 from '../images/cys/arts/33S.jpg'
import Art34 from '../images/cys/arts/34S.jpg'
import Art35 from '../images/cys/arts/35S.jpg'
import Art36 from '../images/cys/arts/36S.jpg'









import Artm1 from '../images/cys/arts/1.jpg'
import Artm2 from '../images/cys/arts/2.jpeg'
import Artm3 from '../images/cys/arts/3.jpeg'
import Artm4 from '../images/cys/arts/4.jpeg'
import Artm5 from '../images/cys/arts/5.jpeg'
import Artm6 from '../images/cys/arts/6.jpg'
import Artm7 from '../images/cys/arts/7.jpg'
import Artm8 from '../images/cys/arts/8.jpeg'
import Artm9 from '../images/cys/arts/9.jpg'
import Artm10 from '../images/cys/arts/10.jpg'
import Artm11 from '../images/cys/arts/11.jpg'
import Artm12 from '../images/cys/arts/12.jpeg'

import Artm13 from '../images/cys/arts/13.jpeg'
import Artm14 from '../images/cys/arts/14.jpg'
import Artm15 from '../images/cys/arts/15.jpeg'
import Artm16 from '../images/cys/arts/16.jpg'
import Artm17 from '../images/cys/arts/17.jpg'
import Artm18 from '../images/cys/arts/18.jpeg'
import Artm19 from '../images/cys/arts/19.jpeg'
import Artm20 from '../images/cys/arts/20.jpeg'
import Artm21 from '../images/cys/arts/21.jpeg'
import Artm22 from '../images/cys/arts/22.jpg'
import Artm23 from '../images/cys/arts/23.jpeg'
import Artm24 from '../images/cys/arts/24.jpeg'

import Artm25 from '../images/cys/arts/25.jpeg'
import Artm26 from '../images/cys/arts/26.jpg'
import Artm27 from '../images/cys/arts/27.jpg'
import Artm28 from '../images/cys/arts/28.jpg'
import Artm29 from '../images/cys/arts/29.jpg'
import Artm30 from '../images/cys/arts/30.jpeg'
import Artm31 from '../images/cys/arts/31.jpg'
import Artm32 from '../images/cys/arts/32.jpeg'
import Artm33 from '../images/cys/arts/33.jpeg'
import Artm34 from '../images/cys/arts/34.jpg'
import Artm35 from '../images/cys/arts/35.jpg'
import Artm36 from '../images/cys/arts/36.jpg'

import MiguelAguirre from '../images/cys/MiguelAguirre.jpg'
import ManuelaCastañeda from '../images/cys/ManuelaCastañeda.jpg'
import Lilygalvan from '../images/cys/Lilygalvan.jpg'
import EmilioCazorla from '../images/cys/EmilioCazorla.jpg'
import MiltonRomán from '../images/cys/MiltonRomán.jpg'


import Arrow_Up from '../images/arrow_up.png'
import Arrow_Down from '../images/arrow_down.png'

import ImgVideoIframe from '../images/cys/imgvideo.jpg'
import Modal from 'react-bootstrap/Modal'
import AudioPlayer from 'react-h5-audio-player';
import { InView } from 'react-intersection-observer';
import notable_artista from '../components/audio/notable_artista.mp3'
import noblecaminoenlaeducacion from '../components/audio/noblecaminoenlaeducacion.mp3'
import colorpuerta from '../components/audio/colorpuerta.mp3'
import besos from '../components/audio/besos.mp3'
import antioquia from '../components/audio/antioquia.mp3'
import agua from '../components/audio/agua.mp3'
import { useLocation } from "@reach/router"
import ShareButton from './share/ShareButton';

const Colorsentimiento = () => {
	const { pathname } = useLocation();

	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [show4, setShow4] = useState(false);
	const [show5, setShow5] = useState(false);
	const [show6, setShow6] = useState(false);
	const [show7, setShow7] = useState(false);
	const [show8, setShow8] = useState(false);
	const [show9, setShow9] = useState(false);
	const [show10, setShow10] = useState(false);
	const [show11, setShow11] = useState(false);
	const [show12, setShow12] = useState(false);
	const [show13, setShow13] = useState(false);
	const [show14, setShow14] = useState(false);
	const [show15, setShow15] = useState(false);
	const [show16, setShow16] = useState(false);
	const [show17, setShow17] = useState(false);

	const [show18, setShow18] = useState(false);
	const [show19, setShow19] = useState(false);
	const [show20, setShow20] = useState(false);
	const [show21, setShow21] = useState(false);
	const [show22, setShow22] = useState(false);
	const [show23, setShow23] = useState(false);
	const [show24, setShow24] = useState(false);
	const [show25, setShow25] = useState(false);
	const [show26, setShow26] = useState(false);
	const [show27, setShow27] = useState(false);
	const [show28, setShow28] = useState(false);
	const [show29, setShow29] = useState(false);
	const [show30, setShow30] = useState(false);
	const [show31, setShow31] = useState(false);
	const [show32, setShow32] = useState(false);
	const [show33, setShow33] = useState(false);
	const [show34, setShow34] = useState(false);
	const [show35, setShow35] = useState(false);
	const [show36, setShow36] = useState(false);
	const [show37, setShow37] = useState(false);
	const [show38, setShow38] = useState(false);
	const [show39, setShow39] = useState(false);
	const [show40, setShow40] = useState(false);
	const [show41, setShow41] = useState(false);
	const [show42, setShow42] = useState(false);


	const [FirstAudio, setFirstAudio] = useState(false)
	const [SecondAudio, setSecondAudio] = useState(false)
	const [ThreeAudio, setThreeAudio] = useState(false)
	const [FourAudio, setFourAudio] = useState(false)
	const [FiveAudio, setFiveAudio] = useState(false)
	const [SixAudio, setSixAudio] = useState(false)

	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	const handleClose3 = () => setShow3(false);
	const handleClose4 = () => setShow4(false);
	const handleClose5 = () => setShow5(false);
	const handleClose6 = () => setShow6(false);
	const handleClose7 = () => setShow7(false);
	const handleClose8 = () => setShow8(false);
	const handleClose9 = () => setShow9(false);
	const handleClose10 = () => setShow10(false);
	const handleClose11 = () => setShow11(false);
	const handleClose12 = () => setShow12(false);
	const handleClose13 = () => setShow13(false);
	const handleClose14 = () => setShow14(false);
	const handleClose15 = () => setShow15(false);
	const handleClose16 = () => setShow16(false);
	const handleClose17 = () => setShow17(false);
	const handleClose18 = () => setShow18(false);
	const handleClose19 = () => setShow19(false);
	const handleClose20 = () => setShow20(false);
	const handleClose21 = () => setShow21(false);
	const handleClose22 = () => setShow22(false);
	const handleClose23 = () => setShow23(false);
	const handleClose24 = () => setShow24(false);
	const handleClose25 = () => setShow25(false);
	const handleClose26 = () => setShow26(false);
	const handleClose27 = () => setShow27(false);
	const handleClose28 = () => setShow28(false);
	const handleClose29 = () => setShow29(false);
	const handleClose30 = () => setShow30(false);
	const handleClose31 = () => setShow31(false);
	const handleClose32 = () => setShow32(false);
	const handleClose33 = () => setShow33(false);
	const handleClose34 = () => setShow34(false);
	const handleClose35 = () => setShow35(false);
	const handleClose36 = () => setShow36(false);
	const handleClose37 = () => setShow37(false);
	const handleClose38 = () => setShow38(false);
	const handleClose39 = () => setShow39(false);
	const handleClose40 = () => setShow40(false);
	const handleClose41 = () => setShow41(false);
	const handleClose42 = () => setShow42(false);


	const handleShow1 = () => setShow1(true);
	const handleShow2 = () => setShow2(true);
	const handleShow3 = () => setShow3(true);
	const handleShow4 = () => setShow4(true);
	const handleShow5 = () => setShow5(true);
	const handleShow6 = () => setShow6(true);
	const handleShow7 = () => setShow7(true);
	const handleShow8 = () => setShow8(true);
	const handleShow9 = () => setShow9(true);
	const handleShow10 = () => setShow10(true);
	const handleShow11 = () => setShow11(true);
	const handleShow12 = () => setShow12(true);


	const handleShow18 = () => setShow18(true);
	const handleShow19 = () => setShow19(true);
	const handleShow20 = () => setShow20(true);
	const handleShow21 = () => setShow21(true);
	const handleShow22 = () => setShow22(true);
	const handleShow23 = () => setShow23(true);
	const handleShow24 = () => setShow24(true);
	const handleShow25 = () => setShow25(true);
	const handleShow26 = () => setShow26(true);
	const handleShow27 = () => setShow27(true);
	const handleShow28 = () => setShow28(true);
	const handleShow29 = () => setShow29(true);
	const handleShow30 = () => setShow30(true);
	const handleShow31 = () => setShow31(true);
	const handleShow32 = () => setShow32(true);
	const handleShow33 = () => setShow33(true);
	const handleShow34 = () => setShow34(true);
	const handleShow35 = () => setShow35(true);
	const handleShow36 = () => setShow36(true);
	const handleShow37 = () => setShow37(true);
	const handleShow38 = () => setShow38(true);
	const handleShow39 = () => setShow39(true);
	const handleShow40 = () => setShow40(true);
	const handleShow41 = () => setShow41(true);

	const ModalMigueAguirre = () => { setShow13(true) }
	const ModalLilianaGalvan = () => { setShow14(true) }
	const ModalMILTONROMAN = () => { setShow15(true) }
	const ModalMANUELACASTAÑEDA = () => { setShow16(true) }
	const ModalEMILIOCAZORLA = () => { setShow17(true) }
	const modalPoesiaquefluye = () => { setShow42(true) }
	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			window.scrollTo(0, 0);
		}
	}, [])

	const size = useWindowSize();

	useEffect(() => {
		if (size.width > 991) {
			var body = document.body;
			body.classList.add("disableScroll");
		}
		return () => {
			if (size.width > 991) {

				body.classList.remove("disableScroll");
			}

		}
	}, [size.width])

	const playOne = () => {
		if (document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseOne = () => {
		if (document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Pause"]').click()
		}

	}

	useEffect(() => {
		if (FirstAudio === false) {
			if (document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('notable_artista')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [FirstAudio])

	const playTwo = () => {
		if (document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseTwo = () => {
		if (document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Pause"]').click()
		}

	}

	useEffect(() => {
		if (SecondAudio === false) {
			if (document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('antioquia')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [SecondAudio])

	const playThree = () => {
		if (document.getElementsByClassName('agua')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('agua')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseThree = () => {
		if (document.getElementsByClassName('agua')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('agua')[0].querySelector('[aria-label="Pause"]').click()
		}
	}

	useEffect(() => {
		if (ThreeAudio === false) {
			if (document.getElementsByClassName('agua')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('agua')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [ThreeAudio])

	const playFour = () => {
		/*if(document.querySelectorAll('[aria-label="Play"]')[3]){
				document.querySelectorAll('[aria-label="Play"]')[3].click()
		}*/
		if (document.getElementsByClassName('besos')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('besos')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseFour = () => {
		if (document.getElementsByClassName('besos')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('besos')[0].querySelector('[aria-label="Pause"]').click()
		}
	}

	useEffect(() => {
		if (FourAudio === false) {
			if (document.getElementsByClassName('besos')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('besos')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [FourAudio])

	const playFive = () => {
		if (document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseFive = () => {
		if (document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Pause"]').click()
		}
	}

	useEffect(() => {
		if (FiveAudio === false) {
			if (document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('colorpuerta')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [FiveAudio])

	const playSix = () => {

		if (document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Play"]')) {
			document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Play"]').click()
		}
	}

	const pauseSix = () => {
		if (document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Pause"]')) {
			document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Pause"]').click()
		}

	}

	useEffect(() => {
		if (SixAudio === false) {
			if (document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Pause"]')) {
				document.getElementsByClassName('noblecaminoenlaeducacion')[0].querySelector('[aria-label="Pause"]').click()
			}
		}
	}, [SixAudio])

	return (
		<Layout
			descritpion={'Enrique Bustamante'}
			title={'Color y sentimiento'}
			subtitile={'UPC'}
			imgurl={"//cultural.upc.edu.pe/static/1-66e77333bffb7909379cf576f3475bf9.jpg"}
		>
			<div className="cys" >
				<AudioPlayer style={{ 'display': 'none' }} className="notable_artista"
					src={notable_artista}
				/>
				<AudioPlayer style={{ 'display': 'none' }} className="antioquia"
					src={antioquia}
				/>
				<AudioPlayer style={{ 'display': 'none' }} className="agua"
					src={agua}
				/>
				<AudioPlayer style={{ 'display': 'none' }} className="besos"
					src={besos}
				/>
				<AudioPlayer style={{ 'display': 'none' }} className="colorpuerta"
					src={colorpuerta}
				/>
				<AudioPlayer style={{ 'display': 'none' }} className="noblecaminoenlaeducacion"
					src={noblecaminoenlaeducacion}
				/>
				<ShareButton url={pathname} colour={'#fff'} iconColour={'#ed1c24'} />

				<div className="sections-buttons-container">
					<button onClick={() => { navigate('#page1ID'); }} className="boton-section-redirect" style={{ background: '#af1f27' }}> <div><p style={{ background: '#af1f27' }}>Color y Sentimiento</p></div> </button>
					<button onClick={() => { navigate('#page4ID'); }} className="boton-section-redirect" style={{ background: '#5d2e91' }}> <div><p style={{ background: '#5d2e91' }}>Notable artista</p></div> </button>
					<button onClick={() => { navigate('#page14IDSECTION') }} className="boton-section-redirect" style={{ background: '#f38aa9' }}> <div><p style={{ background: '#f38aa9' }}>Antioquía</p></div> </button>
					<button onClick={() => { navigate('#page22ID'); }} className="boton-section-redirect" style={{ background: '#427dbf' }}> <div><p style={{ background: '#427dbf' }}>Mundo de color</p></div> </button>
					<button onClick={() => { navigate('#page25ID'); }} className="boton-section-redirect" style={{ background: '#26a37e' }}> <div><p style={{ background: '#26a37e' }}>La Palabra es Sentimiento</p></div> </button>
					<button onClick={() => { navigate('#page351ID'); }} className="boton-section-redirect" style={{ background: '#71c043' }}> <div><p style={{ background: '#71c043' }}>Noble camino en la educación</p></div> </button>
					<button onClick={() => { navigate('#page41ID'); }} className="boton-section-redirect" style={{ background: '#2db24a' }}> <div><p style={{ background: '#2db24a' }}>Conoce más sobre el artista</p></div> </button>
				</div>
				<div>
					<div className="vh-100-1 page1" id="page1ID" style={{ 'background': `url(${Background1}) center center no-repeat`, 'backgroundSize': 'cover' }} >
						<div style={{ 'margin': 'auto' }} >
							<h1 className="d-none">Color y Sentimiento</h1>
							<h3 className="title-I">Color y </h3>
							<h3 className="title-II">Sentimiento</h3>
							<h2 className="subtittle">HOMENAJE A ENRIQUE BUSTAMANTE</h2>
						</div>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page2ID'); }} className="boton-scroll-down"><img alt="flecha hacia arriba" src={Arrow_Down} /></button>
							</div>
						</div>
					</div>
					<div className="vh-100style page2 vh-100styleEspecial" id="page2ID" style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.12648809523809523) 0%),url(${img1}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page1ID'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="flecha hacia arriba" /></button>
								<button onClick={() => { navigate('#page3ID'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="flecha hacia abajo" /></button>
							</div>
						</div>
					</div>
					<div className="vh-100style page3 vh-100styleEspecial embed-container" id="page3ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page2ID'); }} className="boton-scroll-top"><img src={Arrow_Up} alt="flecha hacia arriba" /></button>
								<button onClick={() => { navigate('#page4ID'); }} className="boton-scroll-down"><img src={Arrow_Down} alt="flecha hacia abajo" /></button>
							</div>
						</div>
						<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483197643?title=false&portrait=false&byline=false" width="560" height="315" frameBorder="0"></iframe>
					</div>
					<div>
					</div>
					<InView onChange={(inView, entry) => { setFirstAudio(inView) }} className="Inview1">
						{FirstAudio &&

							<div className="control-play-pause-music">
								<div className="control-background">
									<div className="control-container">
										<div className="first-play">
											<button className="button-play" tabIndex="0" onClick={playOne}>
												<i className="fa fa-play"></i>
											</button>
											<button className="button-pause" id="button-pause" tabIndex="0" onClick={pauseOne}>
												<i className="fa fa-pause"></i>
											</button>
										</div>
										<div className="text-music-container">
											<h2>Escucha su Biografía</h2>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="vh-100style page4 vh-100styleEspecial" id="page4ID" style={{ 'background': `url(${Background2}) center center no-repeat`, 'backgroundSize': 'cover' }}>
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page3ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page5ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-text">
								<h3 className="title">Notable</h3>
								<h3 className="title-II">Artista</h3>
							</div>
						</div>
						<div style={{ 'background': `url(${BackgroundComplete1}) center center no-repeat`, 'backgroundSize': 'cover' }}>


							<div className="vh-100style page5" id="page5ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page4ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page6ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img src={img3} alt="color y sentimiento imagen 3"></img>
										<div className="text-container text-container-responsive-desktop"><p>
											Enrique Bustamante y, en el centro, el maestro
											<span> Adolfo Winternitz</span>
											, cuando ganó el Concurso de
											Grabado de la entonces Escuela de Artes Plásticas
											de la
											<span> Pontificia Universidad Católica </span> (1965)
										</p></div>
										<div className="text-container text-container-responsive-phone"><p>
											Enrique Bustamante junto a su padre, <span>Carlos Enrique Bustamante</span>, en
											la primera exposición de arte <span>“29 Gemas”</span> en la <span>Galería La Familia</span> (1964)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container text-container-responsive-desktop"><p>
											Enrique Bustamante junto a su padre, <span>Carlos Enrique Bustamante</span>, en
											la primera exposición de arte <span>“29 Gemas”</span> en la <span>Galería La Familia</span> (1964)
										</p></div>
										<img alt="color y sentimiento imagen 4" src={img4}></img>
										<div className="text-container text-container-responsive-phone"><p>
											Enrique Bustamante y, en el centro, el maestro
											<span> Adolfo Winternitz</span>
											, cuando ganó el Concurso de
											Grabado de la entonces Escuela de Artes Plásticas
											de la
											<span> Pontificia Universidad Católica </span> (1965)
										</p></div>
									</div>
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
										<img alt="color y sentimiento imagen 5" src={img5}></img>
										<div className="text-container"><p>
											En Barranquilla, Colombia, durante la <span>gira latinoamericana</span> que realizó en los años setenta
										</p></div>
									</div>
								</div>
							</div>

							<div className="vh-100style page6" id="page6ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page5ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page7ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 6" src={img6}></img>
										<div className="text-container"><p>
											Acto de Compromiso de Enrique Bustamante y su esposa <span>Gladys Isaacs</span>, en Panamá, el año que se casaron (1970)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 7" src={img7}></img>
										<div className="text-container"><p>
											El artista junto a su madre y su hija <span>Carla Paola Bustamante Isaacs </span>en los Estados Unidos, cuando viajaba
											a visitar a sus familiares en los años ochenta
										</p></div>
									</div>

									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container"><p>El maestro junto a su madre,
											<span> Victoria Coloma de Bustamante</span>,
											en Pensilvania, Estados Unidos,
											a fines de los setenta
										</p></div>
										<img alt="color y sentimiento imagen 8" src={img8}></img>
									</div>

								</div>
							</div>

							<div className="vh-100style page7" id="page7ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page6ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page8ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 9" src={img9}></img>
										<div className="text-container text-container-responsive-phoneII"><p>
											Enrique Bustamante junto a los artistas <span>Jaime Vásquez, Gerardo Petrucci y Charly Rodríguez</span> en el recién
											inaugurado <span>Instituto Toulouse Lautrec</span> (1985)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container text-container-responsive-desktop"><p>
											Enrique Bustamante junto a los artistas <span>Jaime Vásquez, Gerardo Petrucci y Charly Rodríguez</span> en el recién
											inaugurado <span>Instituto Toulouse Lautrec</span> (1985)
										</p></div>
										<img alt="color y sentimiento imagen 10" src={img10}></img>
										<div className="text-container"><p>
											<span>"Kike"</span>, como lo llaman con cariño, junto a su padre <span>Carlos Enrique Bustamante,</span> en su casa de Lima,
											Perú (1995)
										</p></div>
									</div>

									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container text-container-responsive-phone"><p>
											Enrique Bustamante junto a los artistas <span>Jaime Vásquez, Gerardo Petrucci y Charly Rodríguez</span> en el recién
											inaugurado <span>Instituto Toulouse Lautrec</span> (1985)
										</p></div>
										<div className="text-container text-container-responsive-desktop"><p>
											Al salir de dar Conferencia en Francia, a propósito de su exposición en <span>París</span>. Francia, desde la que partirìa a
											Alemania (1996)
										</p></div>
										<img alt="color y sentimiento imagen 11" src={img11}></img>
										<div className="text-container text-container-responsive-phone text-containerExposición-responsive-especial-phone"><p>
											Al salir de dar Conferencia en Francia, a propósito de su exposición en <span>París</span>. Francia, desde la que partirìa a
											Alemania (1996)
										</p></div>
									</div>
								</div>
							</div>


							<div className="vh-100style page8" id="page8ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page7ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page9ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 12" src={img12}></img>
										<div className="text-container text-container-responsive-phoneII"><p>
											Obra <span>Mural de Mosaicos</span> en La Molina, en convenio y en homenaje a la ciudad de <span>Kelhain, </span>
											Alemania, en los años ochenta
										</p></div>
									</div>
									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container"><p>
											Evento de <span>pintado en vivo</span> para marca de automóviles, reunión privada en el <span>Museo Pedro de Osma</span> (2003)
										</p></div>
										<img alt="color y sentimiento imagen 13" src={img13}></img>
										<div className="text-container"><p>
											Obra <span>Mural de Mosaicos</span> en La Molina, en convenio y en homenaje a la ciudad de <span>Kelhain, </span>
											Alemania, en los años ochenta
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 14" src={img14}></img>
										<div className="text-container"><p>
											Exposición “Encantos del Perú” en la <span>Embajada del Perú</span> en París,
											<span> Francia,</span> por invitación de la <span>Embajadora María Luisa Federici</span> (1996)
										</p></div>
									</div>

								</div>
							</div>

							<div className="vh-100style page9" id="page9ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page8ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page10ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 15" src={img15}></img>
										<div className="text-container"><p>
											Enrique Bustamante junto al Alcalde de Breña, <span>José Antonio Gordillo</span> , en la
											ceremonia de entrega de la <span>Medalla Cívica</span> del Distrito (2014)
										</p></div>

									</div>
									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 16" src={img16}></img>
										<div className="text-container text-container-responsive-desktop"><p>
											Enrique Bustamante, <span>pintor peruano naíf</span>, trabajando en su taller (2000)
										</p></div>
										<div className="text-container text-container-responsive-phone"><p>
											Enrique Bustamante en el reconocimiento que le otorgó el
											<span> Congreso de la República</span> en Salón Conmemorativo del Congreso (2013)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container text-container-responsive-desktop"><p>
											Enrique Bustamante en el reconocimiento que le otorgó el
											<span> Congreso de la República</span> en Salón Conmemorativo del Congreso (2013)
										</p></div>
										<img alt="color y sentimiento imagen 17" src={img17}></img>
										<div className="text-container text-container-responsive-phone"><p>
											Enrique Bustamante, <span>pintor peruano naíf</span>, trabajando en su taller (2000)
										</p></div>
									</div>
								</div>
							</div>


							<div className="vh-100style page10" id="page10ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page9ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page11ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 18" src={img18}></img>
										<div className="text-container"><p>
											Muestra individual <span>“Enrique Bustamante,
												Retrospectiva”</span> (2012)
										</p></div>
									</div>
									<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 19" src={img19}></img>


										<div className="text-container text-container-responsive-phoneII"><p>
											Exposición Colectiva
											<span> “Ars Amandi”</span> (2016)
										</p></div>


										<div className="text-container"><p>
											Enrique Bustamante con su obra <span>“Inmaculada Concepción”</span>, en la
											<span> Bienal de Trujillo,</span> Perú (2017)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<div className="text-container"><p>
											Exposición Colectiva
											<span> “Ars Amandi”</span> (2016)
										</p></div>
										<img alt="color y sentimiento imagen 20" src={img20}></img>

										<div className="text-container text-container-responsive-phoneII"><p>
											Enrique Bustamante con su obra <span>“Inmaculada Concepción”</span>, en la
											<span> Bienal de Trujillo,</span> Perú (2017)
										</p></div>
									</div>
								</div>
							</div>



							<div className="vh-100style page11" id="page11ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page10ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page12ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
										<img alt="color y sentimiento imagen 21" src={img21}></img>
										<div className="text-container text-container-box-1 text-container-responsive-phone"><p>
											Enrique Bustamante en su última Exposición individual <br></br><span>“Los colores de mi cielo”</span> (2017)
										</p></div>
										<div className="text-container text-container-Exposición-responsive-especial-phone"><p>
											Enrique Bustamante junto a <span>Emilio Cazorla</span>, colega y amigo quién le dedicó un cuadro homenaje <span>“La última escena” </span>, en alegoría a su sabiduría (2017)
										</p></div>
										<div className="container-1">
											<img alt="color y sentimiento imagen 22" src={img22}></img>
										</div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
										<div className="text-container text-container-box-1 text-container-responsive-desktop"><p>
											Enrique Bustamante en su última Exposición individual <br></br><span>“Los colores de mi cielo”</span> (2017)
										</p></div>
										<div className="text-container text-container-box-2 text-container-responsive-desktop"><p>
											Presentación y firma de los poemarios: <span>“Vuelos y rondas de luz”</span>,
											<span> “El amor y mil palabras”</span> y <span>“Teatralización del Cosmos”</span>
										</p></div>
										<img alt="color y sentimiento imagen 23" src={img23}></img>
										<div className="text-container text-container-box-2 text-container-responsive-phone"><p>
											Presentación y firma de los poemarios: <span>“Vuelos y rondas de luz”</span>,
											<span> “El amor y mil palabras”</span> y <span>“Teatralización del Cosmos”</span>
										</p></div>
									</div>
								</div>
							</div>



							<div className="vh-100style page12" id="page12ID">
								<div className="controls-page">
									<div className="control-container">
										<button onClick={() => { navigate('#page11ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
										<button onClick={() => { navigate('#page13ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
									</div>
								</div>
								<div className="container-img">
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 24" src={img24}></img>
										<div className="text-container text-container-responsive-desktop"><p>
											Grabación del corto <span>“Homenaje a Enrique Bustamante”</span> para la
											<span> Universidad Peruana de Ciencias Aplicadas – UPC</span> (2020)
										</p></div>
										<div className="text-container text-container-box-2"><p>
											Enrique Bustamante empezando una <span> nueva pintura</span> (2020)
										</p></div>
									</div>
									<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">

										<div className="text-container text-container-responsive-phone text-container-ExposiciónI-responsive-especial-phone"><p>
											Grabación del corto <span>“Homenaje a Enrique Bustamante”</span> para la
											<span> Universidad Peruana de Ciencias Aplicadas – UPC</span> (2020)
										</p></div>
										<div className="text-container text-container-box-1 text-container-responsive-phone text-container-ExposiciónII-responsive-especial-phone"><p>
											Obras del artista ilustrando publicaciones de Arquitectura y Literatura como las de
											<span> Mario Vargas Llosa</span> y <span> José María Arguedas</span>
										</p></div>

										<img alt="color y sentimiento imagen 25" src={img25}></img>
										<div className="container-1 container-responsive-desktop">
											<img alt="color y sentimiento imagen 26" src={img26}></img>
										</div>
										<div className="text-container text-container-box-1 text-container-responsive-desktop"><p>
											Obras del artista ilustrando publicaciones de Arquitectura y Literatura como las de
											<span> Mario Vargas Llosa</span> y <span> José María Arguedas</span>
										</p></div>
									</div>
									<div className="container-1 container-responsive-phone" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 26" src={img26}></img>
									</div>
								</div>
							</div>
						</div>
					</InView>
				</div>

				<div className="vh-100style page13-desktop vh-100styleEspecial" id="page13ID" style={{ 'background': `linear-gradient(270deg, rgba(0,0,0,0.8729866946778712) 45%, rgba(0,0,0,0) 77%),url(${img2}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="controls-page">
						<div className="control-container">
							<button onClick={() => { navigate('#page12ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
							<button onClick={() => { navigate('#page14ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
						</div>
					</div>
					<div className="container-img" >
						<div data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
							<p>
								Recordar el trabajo de un intelectual o artista<br></br>
								contribuye al conocimiento de su tarea.<br></br>
								Recopilar las concepciones e inspiraciones para<br></br>
								un buen final, no hace más que reconocer y<br></br>
								aplaudir la veracidad de su entrega cultural.<br></br>
							</p>
							<p>
								La valoración cultural de este trabajo, me<br></br>
								recuerda mi total agradecimiento a la<br></br>
								Universidad Peruana de Ciencias Aplicadas - UPC<br></br>
								por el homenaje maravilloso, el rescate de mi<br></br>
								labor como artista visual, comunicador<br></br>
								literario y profesor.
							</p>
							<p>
								Gracias por la amabilidad y la dedicación en su<br></br>
								investigación, porque fuera de mí, queda el<br></br>
								recuerdo de mi obra, en su profundidad.
							</p>
							<h2>Enrique Bustamante</h2>
						</div>
					</div>
				</div>

				<div className="vh-100style page13-responsive" id="page13ID" style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,0.8729866946778712) 45%, rgba(0,0,0,0) 77%),url(${img27}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="controls-page">
						<div className="control-container">
							<button onClick={() => { navigate('#page12ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
							<button onClick={() => { navigate('#page14ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
						</div>
					</div>
					<div className="container-img">
						<div>
							<p>
								Recordar el trabajo de un intelectual o artista<br></br>
								contribuye al conocimiento de su tarea.<br></br>
								Recopilar las concepciones e inspiraciones para<br></br>
								un buen final, no hace más que reconocer y<br></br>
								aplaudir la veracidad de su entrega cultural.<br></br>
							</p>
							<p>
								La valoración cultural de este trabajo, me<br></br>
								recuerda mi total agradecimiento a la<br></br>
								Universidad Peruana de Ciencias Aplicadas - UPC<br></br>
								por el homenaje maravilloso, el rescate de mi<br></br>
								labor como artista visual, comunicador<br></br>
								literario y profesor.
							</p>
							<p>
								Gracias por la amabilidad y la dedicación en su<br></br>
								investigación, porque fuera de mí, queda el<br></br>
								recuerdo de mi obra, en su profundidad.
							</p>
							<h2>Enrique Bustamante</h2>
						</div>
					</div>
				</div>









				<InView onChange={(inView, entry) => { setSecondAudio(inView) }} className="Inview2" id="page14IDSECTION">
					{SecondAudio &&

						<div className="control-play-pause-music">
							<div className="control-background">
								<div className="control-container">
									<div className="second-play">
										<button className="button-play" onClick={playTwo}>
											<i className="fa fa-play"></i>
										</button>
										<button className="button-pause" id="button-pause" onClick={pauseTwo}>
											<i className="fa fa-pause"></i>
										</button>
									</div>
									<div className="text-music-container">
										<h2>Antioquía en narración</h2>
									</div>
								</div>
							</div>
						</div>

					}
					<div className="vh-100style page14-desktop vh-100styleEspecial" id="page14ID" style={{ 'background': `url(${Background6}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page13ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page15ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img" >
							<div>
								<h2>Antioquía</h2>
								<h3>EL RETABLO MÁS GRANDE DEL MUNDO</h3>
							</div>
						</div>
					</div>

					<div className="vh-100style page14-responsive vh-100styleEspecial" id="page14ID" style={{ 'background': `url(${Background6Responsive}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page13ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page15ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<div>
								<h2>Antioquía</h2>
								<h3>EL RETABLO MÁS GRANDE DEL MUNDO</h3>
							</div>
						</div>
					</div>



					<div style={{ 'background': `url(${BackgroundComplete2}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="vh-100style page15" id="page15ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page14ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page16ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img" >
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 29" src={img29}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 30" src={img30}></img>
									<div className="text-container"><p>
										El diseño original de la propuesta, es del artista
										peruano Enrique Bustamante, que ganó el año
										2004, el premio del Concurso internacional
										"Colores para Antioquía".
									</p><p>
											Antioquía, también conocida como la Comunidad
											Campesina Espíritu Santo, es un poblado en la
											sierra de Lima, en la cuenca media del Río Lurín,
											a tres horas de la ciudad de Lima.
										</p></div>
								</div>
								<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="text-container"><p>
										El diseño original de la propuesta, es del artista
										peruano Enrique Bustamante, que ganó el año
										2004, el premio del Concurso internacional
										"Colores para Antioquía".
									</p><p>
											Antioquía, también conocida como la Comunidad
											Campesina Espíritu Santo, es un poblado en la
											sierra de Lima, en la cuenca media del Río Lurín,
											a tres horas de la ciudad de Lima.
										</p></div>
								</div>
							</div>
						</div>


						<div className="vh-100style page16" id="page16ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page15ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page17ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img" >
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 31" src={img31}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 32" src={img32}></img>
								</div>
								<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 33" src={img33}></img>
								</div>
								<div className="container-4" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<p>
										El Concurso "Colores para Antioquía" fue una iniciativa de un grupo de artistas extranjeros que llegaron a pintar los paisajes de la zona.
										Más adelante, se materializó un Concurso internacional organizado por la ONG CIED Perú, Centro de Investigación, Educación y Desarrollo.<br></br>
										La premiación se realizó en una ceremonia en el Auditorio Petroperú, resultando ganador el maestro Bustamante entre más de cien propuestas.<br></br>
										El proyecto consistía en pintar 120 viviendas y entidades públicas del lugar, con la propuesta ganadora. Llegaron muchos artistas de Lima y
										otros países y junto a los mismos pobladores colaboraron en la ejecución de la obra ganadora, en el año 2005.
									</p>
								</div>
							</div>
						</div>

						<div className="vh-100style page17" id="page17ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page16ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page18ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img" >
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 34" src={img34}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 35" src={img35}></img>
									<div className="text-container"><p>
										Posteriormente, el proyecto ganó el premio al “Mejor proyecto de ONG en de América
										Latina” otorgado por la Fundación Rockefeller.
									</p>
										<p>
											Además, la Universidad Peruana de Ciencias Aplicadas - UPC, le otorgó el premio
											“Creatividad Empresarial 2006” por tan creativo proyecto.
										</p></div>
								</div>
							</div>
						</div>

						<div className="vh-100style page18" id="page18ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page17ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page19ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img">
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 36" src={img36}></img>
									<div className="text-container"><p>
										También, la organización del Récord Guinness lo declaró “El Retablo más grande del
										Mundo” el año 2007. Y en el 2011, el Ministerio de Cultura ha declarado a la Iglesia de
										Espíritu Santo de Antioquía, como Patrimonio Cultural de la Nación, convirtiendo al
										lugar en punto turístico de la Región Lima, trayendo progreso al pueblo. Los mismos
										habitantes son los que realizan el mantenimiento de las pinturas, conservando así la
										belleza de la propuesta.
									</p></div>
								</div>
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 37" src={img37}></img>
								</div>
							</div>
						</div>

						<div className="vh-100style page19" id="page19ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page18ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page20ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img">

								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 38" src={img38}></img>
								</div>
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 39" src={img39}></img>
								</div>
								<div className="container-4" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="text-container"><p>
										En la actualidad, Antioquía figura en muchas webs de rutas turísticas. En YouTube se encuentran
										más de cien videos y en muchos de ellos mencionan al autor.<br></br>
										Los viajeros buscan conocer el pueblo pintado, el pueblo sacado de un cuento, el gran retablo, el
										pueblo que tiene ese especial encanto gracias al arte de Enrique Bustamante.
									</p></div>
								</div>
								<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div>
										<img alt="color y sentimiento imagen 40" src={img40}></img>
									</div>
								</div>
							</div>
						</div>

						<div className="vh-100style page20" id="page20ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page19ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page21ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>

							<div className="container-img">

								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 41" src={img41}></img>
								</div>
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 42" src={img42}></img>
								</div>
								<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 43" src={img43}></img>
								</div>
							</div>
						</div>
					</div>
				</InView>


				<div className="vh-100style page21 vh-100styleEspecial" id="page21ID" style={{ 'background': `url(${Background8}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="controls-page">
						<div className="control-container">
							<button onClick={() => { navigate('#page20ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
							<button onClick={() => { navigate('#page22ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
						</div>
					</div>
				</div>
				<div style={{ 'background': `url(${BackgroundComplete4}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="vh-100style page22 vh-100styleEspecial" id="page22ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page21ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page35ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<h2>Mundo</h2>
							<h2>de Color</h2>
						</div>
					</div>
					<div className="vh-100style page35 vh-100styleEspecial embed-container" id="page35ID" style={{ 'background': `black`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page22ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page23ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>

						</div>
						<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483196716?title=false&portrait=false&byline=false" width="560" height="315" frameBorder="0"></iframe>
					</div>

					<div className="vh-100style page23 vh-100styleEspecial" id="page23ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page35ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page24ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<div className="container-1" role="button" tabIndex="0" onClick={handleShow1} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow1() } }}>
								<img alt="color y sentimiento imagen Arte 1" src={Art1}></img>
							</div>
							<div className="container-2" role="button" tabIndex="0" onClick={handleShow2} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow2() } }}>
								<img alt="color y sentimiento imagen Arte 2" src={Art2}></img>
							</div>
							<div className="container-3" role="button" tabIndex="0" onClick={handleShow3} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow3() } }}>
								<img alt="color y sentimiento imagen Arte 3" src={Art3}></img>
							</div>

							<div className="container-4" role="button" tabIndex="0" onClick={handleShow4} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow4() } }}>
								<img alt="color y sentimiento imagen Arte 4" src={Art4}></img>
							</div>
							<div className="container-5" role="button" tabIndex="0" onClick={handleShow5} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow5() } }}>
								<img alt="color y sentimiento imagen Arte 5" src={Art5}></img>
							</div>
							<div className="container-6" role="button" tabIndex="0" onClick={handleShow6} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow6() } }}>
								<img alt="color y sentimiento imagen Arte 6" src={Art6}></img>
							</div>

							<div className="container-7" role="button" tabIndex="0" onClick={handleShow7} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow7() } }}>
								<img alt="color y sentimiento imagen Arte 7" src={Art7}></img>
							</div>
							<div className="container-8" role="button" tabIndex="0" onClick={handleShow8} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow8() } }}>
								<img alt="color y sentimiento imagen Arte 8" src={Art8}></img>
							</div>
							<div className="container-9" role="button" tabIndex="0" onClick={handleShow9} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow9() } }}>
								<img alt="color y sentimiento imagen Arte 9" src={Art9}></img>
							</div>
							<div className="container-10" role="button" tabIndex="0" onClick={handleShow10} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow10() } }}>
								<img alt="color y sentimiento imagen Arte 10" src={Art10}></img>
							</div>
							<div className="container-11" role="button" tabIndex="0" onClick={handleShow11} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow11() } }}>
								<img alt="color y sentimiento imagen Arte 11" src={Art11}></img>
							</div>
							<div className="container-12" role="button" tabIndex="0" onClick={handleShow12} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow12() } }}>
								<img alt="color y sentimiento imagen Arte 12" src={Art12}></img>
							</div>

							<div className="container-13" role="button" tabIndex="0" onClick={handleShow18} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow18() } }}>
								<img alt="color y sentimiento imagen Arte 13" src={Art13}></img>
							</div>
							<div className="container-14" role="button" tabIndex="0" onClick={handleShow19} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow19() } }}>
								<img alt="color y sentimiento imagen Arte 14" src={Art14}></img>
							</div>
							<div className="container-15" role="button" tabIndex="0" onClick={handleShow20} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow20() } }}>
								<img alt="color y sentimiento imagen Arte 15" src={Art15}></img>
							</div>
							<div className="container-16" role="button" tabIndex="0" onClick={handleShow21} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow21() } }}>
								<img alt="color y sentimiento imagen Arte 16" src={Art16}></img>
							</div>
							<div className="container-17" role="button" tabIndex="0" onClick={handleShow22} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow22() } }}>
								<img alt="color y sentimiento imagen Arte 17" src={Art17}></img>
							</div>
							<div className="container-18" role="button" tabIndex="0" onClick={handleShow23} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow23() } }}>
								<img alt="color y sentimiento imagen Arte 18" src={Art18}></img>
							</div>
							<div className="container-19" role="button" tabIndex="0" onClick={handleShow24} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow24() } }}>
								<img alt="color y sentimiento imagen Arte 19" src={Art19}></img>
							</div>
							<div className="container-20" role="button" tabIndex="0" onClick={handleShow25} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow25() } }}>
								<img alt="color y sentimiento imagen Arte 20" src={Art20}></img>
							</div>

							<div className="container-21" role="button" tabIndex="0" onClick={handleShow26} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow26() } }}>
								<img alt="color y sentimiento imagen Arte 21" src={Art21}></img>
							</div>
							<div className="container-22" role="button" tabIndex="0" onClick={handleShow27} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow27() } }}>
								<img alt="color y sentimiento imagen Arte 22" src={Art22}></img>
							</div>
							<div className="container-23" role="button" tabIndex="0" onClick={handleShow28} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow28() } }}>
								<img alt="color y sentimiento imagen Arte 23" src={Art23}></img>
							</div>
							<div className="container-24" role="button" tabIndex="0" onClick={handleShow29} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow29() } }}>
								<img alt="color y sentimiento imagen Arte 24" src={Art24}></img>
							</div>
							<div className="container-25" role="button" tabIndex="0" onClick={handleShow30} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow30() } }}>
								<img alt="color y sentimiento imagen Arte 25" src={Art25}></img>
							</div>
							<div className="container-26" role="button" tabIndex="0" onClick={handleShow31} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow31() } }}>
								<img alt="color y sentimiento imagen Arte 26" src={Art26}></img>
							</div>
							<div className="container-27" role="button" tabIndex="0" onClick={handleShow32} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow32() } }}>
								<img alt="color y sentimiento imagen Arte 27" src={Art27}></img>
							</div>
							<div className="container-28" role="button" tabIndex="0" onClick={handleShow33} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow33() } }}>
								<img alt="color y sentimiento imagen Arte 28" src={Art28}></img>
							</div>


							<div className="container-29" role="button" tabIndex="0" onClick={handleShow34} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow34() } }}>
								<img alt="color y sentimiento imagen Arte 29" src={Art29}></img>
							</div>
							<div className="container-30" role="button" tabIndex="0" onClick={handleShow35} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow35() } }}>
								<img alt="color y sentimiento imagen Arte 30" src={Art30}></img>
							</div>
							<div className="container-31" role="button" tabIndex="0" onClick={handleShow36} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow36() } }}>
								<img alt="color y sentimiento imagen Arte 31" src={Art31}></img>
							</div>
							<div className="container-32" role="button" tabIndex="0" onClick={handleShow37} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow37() } }}>
								<img alt="color y sentimiento imagen Arte 32" src={Art32}></img>
							</div>
							<div className="container-33" role="button" tabIndex="0" onClick={handleShow38} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow38() } }}>
								<img alt="color y sentimiento imagen Arte 33" src={Art33}></img>
							</div>
							<div className="container-34" role="button" tabIndex="0" onClick={handleShow39} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow39() } }}>
								<img alt="color y sentimiento imagen Arte 34" src={Art34}></img>
							</div>
							<div className="container-35" role="button" tabIndex="0" onClick={handleShow40} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow40() } }}>
								<img alt="color y sentimiento imagen Arte 35" src={Art35}></img>
							</div>
							<div className="container-36" role="button" tabIndex="0" onClick={handleShow41} onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow41() } }}>
								<img alt="color y sentimiento imagen Arte 36" src={Art36}></img>
							</div>



						</div>
					</div>
				</div>
				<div className="vh-100style page24 vh-100styleEspecial" id="page24ID" style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.12648809523809523) 0%),url(${Background11}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="controls-page">
						<div className="control-container">
							<button onClick={() => { navigate('#page23ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
							<button onClick={() => { navigate('#page25ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
						</div>
					</div>
				</div>
				<div style={{ 'background': `url(${BackgroundComplete3}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="vh-100style page25 vh-100styleEspecial" id="page25ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page24ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page26ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<h2>La Palabra</h2>
							<h2>es Sentimiento</h2>
						</div>
					</div>

					<div className="vh-100style page26 vh-100styleEspecialII" id="page26ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page25ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page27ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2>El</h2>
									<h2><span>S</span>onido</h2>
								</div>
							</div>
							<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
								<div className="text-container">
									<p>Endulza mis sentidos el sonido,<br></br>
										los hace funcionar con alegría<br></br>
										para tener el tiempo de hablar,<br></br>
										soñar despierto en la mañana<br></br>
										dormir paciente en madrugada,<br></br>
										estando allí desde mi infancia<br></br>
										vigilante, austero, divertido.<br></br>
									</p>
									<p>El sonido y el silencio conversan,<br></br>
										intercambian halagos y fantasías<br></br>
										dejan las oscuras formas del engaño<br></br>
										para hundirnos en el lecho azul,<br></br>
										para platicarnos en murmullos<br></br>
										en el silencio del planeta tierra,<br></br>
										en la tristeza de la agreste luna.<br></br>
									</p>
									<p>
										El sonido se vuelve visual,<br></br>
										danza transformado por el viento,<br></br>
										se abre ante los abismos mudos<br></br>
										explicando el porqué de su sentido,<br></br>
										cambiando nuestra piel al escucharlo,<br></br>
										la honestidad que nos hace presa<br></br>
										y la vida que sonríe en primavera.<br></br>
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">“El amor y mil palabras”</p>
								</div>
							</div>
						</div>
					</div>

					<InView onChange={(inView, entry) => { setThreeAudio(inView) }} id="page27ID" className="vh-100style page27 vh-100styleEspecialII">

						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page26ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page28ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="control-play-pause-musicII">
							<div className="control-background">
								<div className="control-container">
									<button className="button-play" tabIndex="0" onClick={playThree}>
										<i className="fa fa-play"></i>
									</button>
									<button className="button-pause" id="button-pause" tabIndex="0" onClick={pauseThree}>
										<i className="fa fa-pause"></i>
									</button>
									<div className="text-music-container">
										<h2>Escucha el poema</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="container-img">

							<div className="container-1">
								<div className="text-container">
									<h2><span>A</span>gua</h2>
								</div>
							</div>
							<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
								<div className="text-container">
									<p>Agua que corres lenta entre mis dedos,<br></br>
										acaricias el rostro de mi amada, mejor lenguaje,<br></br>
										y sanas, adulterando la razón de la tierra<br></br>
										junto a mí o entre piedras y arroyos viviendo<br></br>
										te llevo a mi boca vacía y trago bebiéndote...<br></br>
									</p>
									<p>Agua querida y exquisita, te observo en la orilla del mar,<br></br>
										en los ríos de mi tierra, en lagunas encantadas<br></br>
										te espero, en nuestra iluminada vieja casa,<br></br>
										para que abriendo el cielo mojes mi cuerpo,<br></br>
										vivas en mí y yo en ti, en distancias infinitas...<br></br>
									</p>
									<p>
										Así protegeré tu súplica como hago con el viento,<br></br>
										así construiré una baranda entre tú y el hombre<br></br>
										para ver crecer las flores minuto a minuto cada día,<br></br>
										medir mi cuerpo ahogando mi sed en el silencio,<br></br>
										pintando de verdes el paisaje, la vida, la alborada...
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">“Teatralización del Cosmos”</p>
								</div>
							</div>
						</div>
					</InView>

					<div className="vh-100style page28 vh-100styleEspecialII" id="page28ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page27ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page29ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2><span>E</span>cología</h2>
								</div>
							</div>
							<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
								<div className="text-container">
									<p>Sublime santuario de los pájaros<br></br>
										que respetan la naturaleza y la vida...<br></br>
									</p>
									<p>Gigante herido, deforestación alterando el clima<br></br>
										océano asediado, turbio, sucio, forastero de viaje...<br></br>
									</p>
									<p>
										Hombre invasor de especies extrañas<br></br>
										chatarrero de residuos, esclavo de la basura<br></br>
										que contamina, erosiona, destruye...<br></br>
									</p>
									<p>
										Energías renovables, vida que es más vida,<br></br>
										color que es avance, tierra magnánima<br></br>
										civilización del mal consumo...<br></br>
									</p>
									<p>
										Ecología estás allí pensando<br></br>
										cuando te dañan a ti, nos dañan a todos<br></br>
										deshumanizan el planeta, marcan el final de la vida<br></br>
										planeta mutilado, apocalíptico, caótico...
									</p>
									<p>
										Última gota de amor que se convierte en odio,<br></br>
										catástrofe indescriptible que transforma<br></br>
										la vida de unos y otros...y de aquellos...<br></br>
									</p>
									<p>Adiós ciclo reproductor, amenaza invisible,<br></br>
										desintegración, planeta amado y triste,<br></br>
										aire envenenado, desierto en avance,<br></br>
										tubos de escape, nubes de azufre, tierra sobreexplotada,<br></br>
										aguasturbias, efecto invernadero<br></br>
										elementos metálicos abrumadores,<br></br>
										virus viajeros, muerte química,<br></br>
										invierno nuclear, diluvio que cae<br></br>
										y no lava, no redime, no libera,<br></br>
										capa de ozono, naturaleza en peligro.
									</p>
									<p>
										Eres la gran casa para vivir todos,<br></br>
										donde habitan mis recuerdos, el abrazo, la risa, el beso,<br></br>
										el llanto de mamá, el canto del ave, el rojo de la tarde...<br></br>
										el espejo que conoce nuestra novela cotidiana,<br></br>
										el libro que se abrió para obsequiarnos el primer cuento,<br></br>
										nuestra escuela, el avioncito de papel,<br></br>
										la manzana de esta mañana, el caudal del río,<br></br>
										la piedra y la paja, la arena y el trigo,<br></br>
										el maíz y la retama, el algarrobo y las cabras,<br></br>
										la vieja máquina de escribir, los patines y la bicicleta,<br></br>
										los árboles de la ventana del abuelo,<br></br>
										los geranios blancos camino hacia la iglesia,<br></br>
										el barrio y los amigos, las campanadas del retorno,<br></br>
										la ilusión del amor vivido…</p>
									<p>
										¿Dónde está el último refugio, entrañas de mujer,<br></br> para prolongar la vida?<br></br>
										despertando las conciencias,<br></br>
										preservando el paisaje,<br></br>
										el cuerpo entero, la única historia posible,<br></br>
										nuestro escenario, nuestro espacio ilusorio<br></br> donde nacen las palabras,<br></br>
										los pensamientos gritan y las voces callan,<br></br>
										la biblioteca digital de mi hijo amado<br></br>
										el perdón de los pecados, el rosario de Santa Rosa,<br></br>
										el sueño de Sizú, la molestia de Dalí,<br></br>
										el tic tac de mi reloj adolescente,<br></br>
										mi cama descansando cuando cae la tarde…
									</p>
									<p>
										Paisaje mutante, plantas distintas, aves sin nido,<br></br>
										hombres cuya sombra pasó al olvido…<br></br>
										Amado planeta, ecosistema de aire,<br></br>
										oxígeno, vapor de agua, lluvia, sol y fuego.<br></br>
									</p>
									<p>
										Biomasa orgánica, laboratorio diabólico y cotidiano,<br></br>
										origen de nuestra propia vida<br></br>
										y nuestra cercana muerte<br></br>
										donde se rehacen mis poemas<br></br>
										cuando amaneces a mi lado con la ventana abierta,<br></br>
										el sol alumbrando desde temprano<br></br>
										la luna descansando en la alborada<br></br>
										del pecado perdonado, del morder las entrañas,<br></br>
										y seguir cazando las aves<br></br>
										en la cercana tumba que compartimos todos,<br></br>
										en la dulce canción que anuncia un buen mañana…<br></br>
									</p>
									<p>
										Abrazar la luna enamorada,<br></br>
										despertar con los ojos y el corazón ilusionados<br></br> del amor verdadero<br></br>
										derramando miel en los labios<br></br>
										para saborear mientras cierro mis ojos<br></br>
										y crean los dibujos infantiles<br></br>
										que dicen con ternura la importante vida de los niños<br></br>
										de la perspectiva hacia casa<br></br>
										y los animales corriendo tras las aves,<br></br>
										cuando dejamos el instinto para obrar como normales<br></br>
										cuando apasionados regalamos nuestra<br></br> energía a la madre tierra<br></br>
										y salvamos del planeta lo que aún nos queda:<br></br>
									</p>
									<p>
										El amor, la sonrisa, los ojos que trasmiten dulzura,<br></br>
										las palabras bellas,<br></br>
										las secretas obsesiones,<br></br>
										los besos aún sinceros,<br></br>
										la inocencia, la alegría, lo extraordinario, la tranquilidad,<br></br>
										el Padre nuestro, el Ave María,<br></br>
										la capa de ozono, el descanso,<br></br>
										la luz, el soñar,<br></br>
										por siempre soñar,<br></br>
										soñar…<br></br>
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">“Teatralización del Cosmos”</p>
								</div>
							</div>
						</div>
					</div>


					<InView onChange={(inView, entry) => { setFourAudio(inView) }} className="vh-100style page29 vh-100styleEspecialII" id="page29ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page28ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page30ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="control-play-pause-musicII">
							<div className="control-background">
								<div className="control-container">
									<button className="button-play" tabIndex="0" onClick={playFour}>
										<i className="fa fa-play"></i>
									</button>
									<button className="button-pause" id="button-pause" tabIndex="0" onClick={pauseFour}>
										<i className="fa fa-pause"></i>
									</button>
									<div className="text-music-container">
										<h2>Escucha el poema</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2><span>B</span>esos</h2>
								</div>
							</div>
							<div className="container-2"  >
								<div className="text-container" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<p>Hay besos que guardo en lo profundo de mi pensamiento,<br></br>
										los que reservo en una cabina de un amor malentendido,<br></br>
										los que ablandaron mi corazón y abrigaron con sonrisas<br></br>
										mi tranquilidad de amar y ser correspondido...<br></br>
									</p>
									<p>Hay besos guardados en lo festivo de tu mirada,<br></br>
										en el balance de la poesía que más te agrada,<br></br>
										volando por la ventana cada mañana<br></br>
										y volviendo en la noche soñada enriquecidos ...<br></br>
									</p>
									<p>Hay besos tuyos y los míos, sensación<br></br>
										de haberte amado ayer y no lo olvido,<br></br>
										la ironía de tu encanto y mi desapego,<br></br>
										el inicio de nuestro amor y mi feliz lamento...<br></br>
									</p>
									<p>Entonces mi piel se eriza, el calor habita en mi sangre<br></br>
										para acercarme a ti con el más profundo amor.<br></br>
										para despertar nuestro apetito que avecina<br></br>
										la razón de estar aquí, contigo, juntos en un beso.<br></br>
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">“El amor y mil palabras”</p>
								</div>
							</div>
						</div>
					</InView>


					<div className="vh-100style page30 vh-100styleEspecialII" id="page30ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page29ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page31ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2>Mis</h2>
									<h2><span>P</span>alomas</h2>
								</div>
							</div>
							<div className="container-2"  >
								<div className="text-container" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<p>Como el viento<br></br>
										vuelan a lo alto<br></br>
										mecen sus alas<br></br>
										románticos encantos<br></br>
										de los sueños azules,<br></br>
										del baile entre pétalos y hojas,<br></br>
										mientras mecánicos alarifes construyen<br></br>
										pacientemente sus nidos,<br></br>
										rincón de encuentros<br></br>
										de arrumacos donde el tiempo<br></br>
										se ha detenido por siempre.<br></br>
									</p>
								</div>
							</div>
						</div>
					</div>
					<InView onChange={(inView, entry) => { setFiveAudio(inView) }} className="vh-100style page31 vh-100styleEspecialII" id="page31ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page30ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page32ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="control-play-pause-musicII">
							<div className="control-background">
								<div className="control-container">
									<button className="button-play" tabIndex="0" onClick={playFive}>
										<i className="fa fa-play"></i>
									</button>
									<button className="button-pause" id="button-pause" tabIndex="0" onClick={pauseFive}>
										<i className="fa fa-pause"></i>
									</button>
									<div className="text-music-container">
										<h2>Escucha el poema</h2>
									</div>
								</div>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2><span>C</span>olor</h2>
									<h2><span>P</span>uerta</h2>
								</div>
							</div>
							<div className="container-2">
								<div className="text-container" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<p>Hay puertas que tienen la pasión del encanto,<br></br>
										las que se han detenido en el hall de nuestra casa,<br></br>
										las que son pequeñas y las que son grandes,<br></br>
										las que están talladas y las simples,<br></br>
										las que guardan encanto naciente...<br></br>
									</p>
									<p>Hay las que siempre están abiertas,<br></br>
										las que se cierran con soberbia y matan recuerdos,<br></br>
										las que son dolorosas y se fatigan,<br></br>
										las que poseen ritmo y bailan en vaivén,<br></br>
										las que tienen soledad y nadie las toca...<br></br>
									</p>
									<p>Hay puertas que siempre se lamentan,<br></br>
										las que matan la alegría de nuestra visita,<br></br>
										las que se creen lo máximo y son nada,<br></br>
										las que son fábula para engañar a la gente,<br></br>
										las que han sido acribilladas cada noche.<br></br>
									</p>
									<p>
										Así el color de ellas se pasó a nuestra piel<br></br>
										como quien lanza un petardo a la deriva,<br></br>
										como quien hiere y mata un cordero<br></br>
										sin respeto, sin humildad, sin asomo de asistencia,<br></br>
										solo mirando el color de lo que envidia…<br></br>
									</p>
									<p>
										Pero también hay puertas que cierran el tabernáculo,<br></br>
										se abren para recibir a Papá Noel una navidad cualquiera,<br></br>
										las que esperan el regreso de un amor en madrugada,<br></br>
										las que sirvieron para enjuagar nuestro llanto,<br></br>
										las que aún tienen el encanto de la espera…<br></br>
									</p>
									<p>
										Por qué comparar su piel con la nuestra<br></br>
										si ella encierra nuestro vehículo en su interior,<br></br>
										se abre para la esperanza y la risa<br></br>
										y grita, en la cuadra, en la calle, en la avenida,<br></br>
										sin reclamar, sin cerrar, solo amando.<br></br>
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">“Teatralización del Cosmos”</p>
								</div>
							</div>
						</div>
					</InView>

					<div className="vh-100style page32 vh-100styleEspecialII" id="page32ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page31ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page33ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2><span>P</span>ensamiento</h2>
									<h2>167</h2>
								</div>
							</div>
							<div className="container-2">
								<div className="text-container" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<p>Son tantos, están pendientes de mi actividad, mi vida, mis<br></br>
										sueños, mis escritos, mis pinturas, mis consejos y mi cabello<br></br>
										blanco camino a nieve.<br></br>
									</p>
									<p>Son tantos como un personal diccionario donde cada cual<br></br>
										aparece subrayado y abrazado en la distancia del recuerdo.<br></br>
										Son tantos que aparecen en mis pensamientos, en mis lágrimas<br></br>
										y en mis recursos agradables del presente. No son temporales,<br></br>
										tampoco lucen en su pecho una medalla con mi nombre escrito<br></br>
										o grabado en algún moderno sistema. Son tantos que han<br></br>
										convertido nuestra relación en un matrimonio de sueños,<br></br>
										recursos, abrazos, besos y los más bellos pensamientos para<br></br>
										brindarme la oportunidad de seguir viviendo.<br></br>
									</p>
									<p>Están pendientes de mis hechos, mis relatos, mis grafías y mis<br></br>
										pensamientos. Tratan de convencerme de que existo en sus<br></br>
										instintos para amar, y en mis sueños del no despertar. Están en<br></br>
										cada parte de mí, en cada movimiento de mi cuerpo, en cada<br></br>
										sueño de atracción. Tienen la riqueza espiritual para sacarme<br></br>
										del peligro y convencerme en nuestra relación.<br></br>
									</p>
									<p>A la noticia de mi problema de salud reciente, han respondido<br></br>
										con palabras de aliento, con las llamadas telefónicas más<br></br> distantes
										con los sistemas contemporáneos de comunicación, <br></br> con blanquecina alegría, con
										la medicina más curativa,<br></br> con las flores más perfumadas. Por esas tantas razones,<br></br> quiero agradecer una vez más a quienes
										les alientan con sus <br></br>palabras, sus saludos, su atracción.<br></br> Ahora me toca a mí agradecer a los amigos, familiares,<br></br> ex alumnos,
										colegas, amigos del arte, creyentes religiosos que,<br></br> desde los más cercanos y lejanos rincones del planeta,<br></br> me vienen saludando y obsequiándome
										tranquila aceptación <br></br>de mi salud. Como todos ellos, también persigo sueños y creo<br></br> no haberlos aun totalmente cumplido.
									</p>
									<p>
										Gracias por su aliento, por la sutileza para tratar el tema.<br></br> Gracias por
										la cercanía de sus vocablos, la realización de<br></br> sus deseos y la sonrisa
										que empieza a regalarme la vida,<br></br>    devolviendo lo que recientemente
										había perdido.<br></br> Gracias por ser extremadamente bellos y que sea el
										amor <br></br> el que siga acompañando sus buenos deseos...<br></br>
										Gracias.
									</p>
									<p className="text-poesia">Enrique Bustamante, 2020</p>
								</div>
							</div>
						</div>
					</div>

					<div className="vh-100style page33 vh-100styleEspecialII" id="page33ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page32ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page34ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2><span>F</span>luye</h2>
								</div>
							</div>
							<div className="container-2">
								<div className="text-container" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<p>Fluye mi amor hacia ti mi azucena encantada<br></br>
										para aprovechar tu aroma regado al viento,<br></br>
										para suplantar tu color bajo los ojos míos<br></br>
										entregándote con profundo amor a mi pecho....<br></br>
									</p>
									<p>Fluye tu piel al pie de esta linda noche<br></br>
										para formar con tu cuerpo, única figura,<br></br>
										para encontrar en la totalidad de nuestro ser<br></br>
										la eterna profundidad del querer tocarnos...<br></br>
									</p>
									<p>Fluye nuestro pensamiento a la deriva,<br></br>
										nacen del desorden de nuestros sueños fortuitos<br></br>
										sienten, tocan, escapan como seda entre tus manos<br></br>
										como carbón de amor que quema en la hoguera...<br></br>
									</p>
									<p>Así los astros y las estrellas cohabitan con ternura,<br></br>
										el mar y el cielo cambian de color y se arrebatan,<br></br>
										danza la algarabía de reencontrarnos hermosos<br></br>
										para disfrutar de ti y de mí, hoy más fervientes.<br></br>
									</p>
									<p className="text-poesia">Del poemario</p>
									<p className="text-poesia">"El amor y mil palabras"</p>
								</div>
							</div>
						</div>
					</div>

					<div className="vh-100style page34 vh-100styleEspecial" id="page34ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page33ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page351ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
								<div className="text-container">
									<div className="text-container-tittle">
										<h2>UNA MIRADA EN LA POESÍA “FLUYE”</h2>
										<h3>Por: Miguel Figueroa (*)</h3>
									</div>
									<div className="text-container-description">
										<p>(*) Biólogo especialista en comportamiento humano y neuro-comunicación, Magíster en con maestría en Psicología del
											Trabajo y las Organizaciones y con estudios de Postgrado en Micro-expresiones y Lenguaje no verbal. CEO de La Neurona
											Reina, Empresa empresa dedicada al desarrollo de habilidades blandas, Clima clima organizacional, trabajo en equipo y
											liderazgo. Catedrático en los cursos de Creatividad y Habla en Público de la Universidad Peruana de Ciencias Aplicadas -
											UPC. Conferencista internacional en sobre neuro-comunicación, neuro-oratoria, storytelling y lenguaje no verbal. Ganador
											del Festival Internacional “Cuéntamelo 2009”, organizado por el Centro Cultural Peruano Británico
										</p>
									</div>
								</div>
							</div>
							<div className="container-2">
								<div role="button" tabIndex="0" onClick={modalPoesiaquefluye} onKeyDown={(ev) => { if (ev.keyCode === 13) { modalPoesiaquefluye() } }}>
									<img src={ImgVideoIframe} alt="color y sentimiento video"></img>

								</div>
							</div>
							<div className="container-3"  >
								<div className="text-container-description">
									<p>(*) Biólogo especialista en comportamiento humano y neuro-comunicación, Magíster en con maestría en Psicología del
										Trabajo y las Organizaciones y con estudios de Postgrado en Micro-expresiones y Lenguaje no verbal. CEO de La Neurona
										Reina, Empresa empresa dedicada al desarrollo de habilidades blandas, Clima clima organizacional, trabajo en equipo y
										liderazgo. Catedrático en los cursos de Creatividad y Habla en Público de la Universidad Peruana de Ciencias Aplicadas -
										UPC. Conferencista internacional en sobre neuro-comunicación, neuro-oratoria, storytelling y lenguaje no verbal. Ganador
										del Festival Internacional “Cuéntamelo 2009”, organizado por el Centro Cultural Peruano Británico
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.12648809523809523) 0%),url(${BackgroundComplete5}) center center no-repeat`, 'backgroundSize': 'cover' }}>
					<div className="vh-100style page35 vh-100styleEspecial" id="page351ID">
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page34ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page36ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<h2>Noble camino</h2>
							<h2>en la educación</h2>
						</div>
					</div>

					<InView onChange={(inView, entry) => { setSixAudio(inView); }} className="Inview1">
						{SixAudio &&
							<div className="control-play-pause-music">
								<div className="control-background">
									<div className="control-container">
										<div className="six-play">
											<button className="button-play" tabIndex="0" onClick={playSix}>
												<i className="fa fa-play"></i>
											</button>
											<button className="button-pause" id="button-pause" tabIndex="0" onClick={pauseSix}>
												<i className="fa fa-pause"></i>
											</button>
										</div>
										<div className="text-music-container">
											<h2>Escucha la narración</h2>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="vh-100style page36" id="page36ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page351ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page37ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img">
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 44" src={img44}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="container-3">
										<img alt="color y sentimiento imagen 45" src={img45}></img>
									</div>
									<div className="container-4">
										<img alt="color y sentimiento imagen 46" src={img46}></img>
									</div>
									<div className="container-5">
										<div className="text-container"><p>
											Enrique Bustamante tiene una gran vocación por la docencia. Este año 2020, está cumpliendo
											55 años en el camino de la enseñanza de las artes en el Perú.
										</p><p>
												Se inició como profesor de Arte del colegio parroquial Nuestra Señora de los Desamparados,
												de los padres Jesuitas, entre los años 1964 y 1970.
											</p></div>
									</div>
								</div>
								<div className="container-4 responsive" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 46" src={img46}></img>
								</div>
								<div className="container-3 responsive" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 45" src={img45}></img>
								</div>
								<div className="container-5 responsive" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="text-container"><p>
										Enrique Bustamante tiene una gran vocación por la docencia. Este año 2020, está cumpliendo
										55 años en el camino de la enseñanza de las artes en el Perú.
									</p><p>
											Se inició como profesor de Arte del colegio parroquial Nuestra Señora de los Desamparados,
											de los padres Jesuitas, entre los años 1964 y 1970.
										</p></div>
								</div>
							</div>
						</div>


						<div className="vh-100style page37" id="page37ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page36ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page38ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img">
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<img alt="color y sentimiento imagen 47" src={img47}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<div className="container-4">
										<div className="text-container"><p>
											Más adelante, es nombrado Asesor
											Académico de Extensión y Proyección
											Universitaria de San Martín de Porres
											en Lima, entre los años 1980 y 1990.
										</p><p>
												En 1983, entra como profesor de
												Historia del Arte en el Instituto
												Superior Tecnológico CEPEA, en el
												Programa de Turismo y Hotelería,
												donde dicta clases durante diez años.
												Y también, ha sido profesor de la
												Escuela Superior de Arte Orval en
												1994.
											</p>
											<p>En el año 1985, ingresa como profesor
												de Historia del Arte al recién
												inaugurado instituto Toulouse Lautrec,
												que se especializó sólo en Diseño.
											</p>
										</div>
									</div>
									<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
										<img alt="color y sentimiento imagen 48" src={img48}></img>
									</div>
								</div>
							</div>
						</div>

						<div className="vh-100style page37-responsive">
							<div className="container-img">
								<div className="container-3" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<img alt="color y sentimiento imagen 48" src={img48}></img>
								</div>
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<img alt="color y sentimiento imagen 47" src={img47}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<div className="text-container"><p>
										Más adelante, es nombrado Asesor
										Académico de Extensión y Proyección
										Universitaria de San Martín de Porres
										en Lima, entre los años 1980 y 1990.
									</p><p>
											En 1983, entra como profesor de
											Historia del Arte en el Instituto
											Superior Tecnológico CEPEA, en el
											Programa de Turismo y Hotelería,
											donde dicta clases durante diez años.
											Y también, ha sido profesor de la
											Escuela Superior de Arte Orval en
											1994.
										</p>
										<p>En el año 1985, ingresa como profesor
											de Historia del Arte al recién
											inaugurado instituto Toulouse Lautrec,
											que se especializó sólo en Diseño.
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="vh-100style page38" id="page38ID">
							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page37ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page39ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>

							<div className="container-img">
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<img alt="color y sentimiento imagen 49" src={img49}></img>
								</div>
								<div className="container-2" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<div className="container-3">
										<img alt="color y sentimiento imagen 50" src={img50}></img>
									</div>
									<div className="container-4">
										<div className="text-container"><p>
											Durante todos estos años ha continuado en esa institución,
											dictando talleres de diversas materias relacionadas al arte
											y al diseño hasta el día de hoy, que dicta clases en el
											sistema online, dada la coyuntura actual. Dicha institución
											le otorgó un reconocimiento especial por sus 30 años de
											labor el año 2017.
										</p>
											<p>También continúa siendo profesor de Arte en el colegio
												peruano-francés André Malraux de Monterrico, desde el
												año 1992.
											</p>
										</div>
									</div>
								</div>
								<div className="container-5" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="text-container"><p>
										Durante todos estos años ha continuado en esa institución,
										dictando talleres de diversas materias relacionadas al arte
										y al diseño hasta el día de hoy, que dicta clases en el
										sistema online, dada la coyuntura actual. Dicha institución
										le otorgó un reconocimiento especial por sus 30 años de
										labor el año 2017.
									</p>
										<p>También continúa siendo profesor de Arte en el colegio
											peruano-francés André Malraux de Monterrico, desde el
											año 1992.
										</p>
									</div>
								</div>
							</div>
						</div>


						<div className="vh-100style page39" id="page39ID">

							<div className="controls-page">
								<div className="control-container">
									<button onClick={() => { navigate('#page38ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
									<button onClick={() => { navigate('#page40ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
								</div>
							</div>
							<div className="container-img">
								<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back" >
									<div className="container-2">
										<img alt="color y sentimiento imagen 51" src={img51}></img>
									</div>
									<div className="container-3">
										<div className="text-container"><p>
											Y en la Universidad Peruana de Ciencias Aplicadas - UPC, dicta los
											talleres de Pintura desde el año 2005. Además, ha brindado diversos
											talleres de artes y expresiones afines en la Alianza Francesa de Lima, la
											Alianza Francesa de Trujillo, la Escuela de Bellas Artes de Piura, Centro
											Cultural Peruano Japonés, colegio Raúl Porras Barrenechea, Instituto
											Superior de Turismo CEVATUR y otros talleres ofrecidos en el Perú y en
											el extranjero.
										</p>
										</div>
									</div>
								</div>
								<div className="container-7" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="text-container"><p>
										Y en la Universidad Peruana de Ciencias Aplicadas - UPC, dicta los
										talleres de Pintura desde el año 2005. Además, ha brindado diversos
										talleres de artes y expresiones afines en la Alianza Francesa de Lima, la
										Alianza Francesa de Trujillo, la Escuela de Bellas Artes de Piura, Centro
										Cultural Peruano Japonés, colegio Raúl Porras Barrenechea, Instituto
										Superior de Turismo CEVATUR y otros talleres ofrecidos en el Perú y en
										el extranjero.
									</p>
									</div>
									<div className="text-container"><p>
										Durante <span>éstas más de cinco décadas de labor </span>
										como maestro, Enrique Bustamante sigue dejando gran legado entre miles de
										estudiantes de todas las edades que reconocen al profesor como
										fuente de inspiración, motivación y gran aporte para su
										desarrollo formativo, profesional y humano.
									</p>
										<p><span>¡Gracias maestro!</span></p>
									</div>
								</div>
								<div className="container-4" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
									<div className="container-5">
										<div className="text-container"><p>
											Durante <span>éstas más de cinco décadas de labor </span>
											como maestro, Enrique Bustamante sigue dejando gran legado entre miles de
											estudiantes de todas las edades que reconocen al profesor como
											fuente de inspiración, motivación y gran aporte para su
											desarrollo formativo, profesional y humano.
										</p>
											<p><span>¡Gracias maestro!</span></p>
										</div>
									</div>
									<div className="container-6">
										<img alt="color y sentimiento imagen 52" src={img52}></img>
									</div>
								</div>
							</div>
						</div>
					</InView>

					<div className="vh-100style page40 vh-100styleEspecial" id="page40ID">

						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page39ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page41ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<div className="container-1" data-sal="zoom-in" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-out-back">
								<div className="container-8">
									<h2>Legado</h2>
								</div>
								<div className="container-9">
									<h3>Sumado a la pintura, Enrique Bustamante se ha desempeñado en el campo del
										diseño de interiores, gráfico y textil; en el diseño de joyas, mobiliario, vestuario
										y batik; en cerámica, mosaicos, vitrales, objetos-arte, entre otras
										manifestaciones artísticas.
									</h3>
								</div>
							</div>

							<div className="container-2">
								<div className="container-3" role="button" tabIndex="0" onClick={ModalMigueAguirre} onKeyDown={(ev) => { if (ev.keyCode === 13) { ModalMigueAguirre() } }}>
									<img alt="Miguel Aguirre" src={MiguelAguirre}></img>
									<div className="text-container">
										<h4>MIGUEL AGUIRRE</h4>
									</div>
								</div>
								<div className="container-4" role="button" tabIndex="0" onClick={ModalLilianaGalvan} onKeyDown={(ev) => { if (ev.keyCode === 13) { ModalLilianaGalvan() } }}>
									<img alt="Lily galvan" src={Lilygalvan}></img>
									<div className="text-container">
										<h4>LILIANA GALVÁN</h4>
									</div>
								</div>
								<div className="container-5" role="button" tabIndex="0" onClick={ModalMILTONROMAN} onKeyDown={(ev) => { if (ev.keyCode === 13) { ModalMILTONROMAN() } }}>
									<img alt="Milton Román" src={MiltonRomán}></img>
									<div className="text-container">
										<h4>MILTON ROMÁN</h4>
									</div>
								</div>
								<div className="container-6" role="button" tabIndex="0" onClick={ModalMANUELACASTAÑEDA} onKeyDown={(ev) => { if (ev.keyCode === 13) { ModalMANUELACASTAÑEDA() } }}>
									<img alt="Manuela Castañeda" src={ManuelaCastañeda}></img>
									<div className="text-container">
										<h4>MANUELA CASTAÑEDA</h4>
									</div>
								</div>
								<div className="container-7" role="button" tabIndex="0" onClick={ModalEMILIOCAZORLA} onKeyDown={(ev) => { if (ev.keyCode === 13) { ModalEMILIOCAZORLA() } }}>
									<img alt="Emilio Cazorla" src={EmilioCazorla}></img>
									<div className="text-container">
										<h4>EMILIO CAZORLA</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div>
					<div className="vh-100style page41 vh-100styleEspecial" id="page41ID" style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.12648809523809523) 0%),url(${Background14}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page40ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page42ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>

						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2 className="h2-first">CONOCE MÁS SOBRE EL ARTISTA</h2>
									<a rel="noopener noreferrer" target="_blank" href="http://enriquebustamante.blogspot.com/"><h3>www.enriquebustamante.blogspot.com</h3></a>
									<a rel="noopener noreferrer" target="_blank" href="http://artistaenriquebustamante.simplesite.com/"><h3>http://artistaenriquebustamante.simplesite.com/</h3></a>
									<a rel="noopener noreferrer" target="_blank" href="https://www.aicoa.org/"><h3>www.aicoa.org</h3></a>
									<a rel="noopener noreferrer" target="_blank" href="http://enriquebustamante.artelista.com"><h3>http://enriquebustamante.artelista.com</h3></a>
									<a rel="noopener noreferrer" target="_blank" href="https://www.picassomio.com/enrique-bustamante/exhibition.html"><h3>https://www.picassomio.com/enrique-bustamante/exhibition.html</h3></a>
								</div>
							</div>
							<div className="container-2">
							</div>
							<div className="container-3">
								<div className="text-container">
									<h2 className="h2-last">CONTACTO</h2>
									<div>
										<div className="container-icon">
											<i className="fa fa-envelope"></i>
										</div>
										<h4>ebc110@yahoo.com</h4>
									</div>
									<div>
										<div className="container-icon">
											<i className="fa fa-mobile"></i>
										</div>
										<h4>+51 994091775</h4>
									</div>
									<div>
										<div className="container-icon">
											<i className="fa fa-phone"></i>
										</div>
										<h4>+51 4315035</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="vh-100style page42 vh-100styleEspecial" id="page42ID" style={{ 'background': `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.12648809523809523) 0%),url(${Background14}) center center no-repeat`, 'backgroundSize': 'cover' }}>
						<div className="controls-page">
							<div className="control-container">
								<button onClick={() => { navigate('#page41ID'); }} className="boton-scroll-top"><img alt="flecha hacia arriba" src={Arrow_Up} /></button>
								<button onClick={() => { navigate('#page1ID'); }} className="boton-scroll-down"><img alt="flecha hacia abajo" src={Arrow_Down} /></button>
							</div>
						</div>
						<div className="container-img">
							<div className="container-1">
								<div className="text-container">
									<h2 className="h2-first">CRÉDITOS</h2>
									<h3>Curaduría, dirección y producción: Nelli Escudero</h3>
									<h3>Dirección de fotografía, cámara y edición de videos: Santiago Rey de Castro</h3>
									<h3>Asistente de investigación: Carlos Rivera Díaz</h3>
									<h3>Locución y edición de audios: Nelli Escudero</h3>
									<h3 className="h2-space">Producción ejecutiva: Erika Lastra</h3>
									<h3>UPC Cultural</h3>
									<h3>Universidad Peruana de Ciencias Aplicadas - UPC</h3>
								</div>
							</div>
							<div className="container-2">
								<div className="text-container">
									<h2 className="h2-last">MÚSICA DE VIDEOS Y AUDIOS</h2>
									<ul>
										<li><span className="point">.</span><span className="text">Serenata Nº 13 para cuerdas en sol mayor: </span>
											Eine Kleine Nachtmusik (Una Pequeña serenata
											nocturna), por Wolfgand Amadeus Mozart (Creative Commons - Atribución 3.0 Sin portar— CC BY 3.0)
										</li>
										<li><span className="point">.</span>
											<span className="text">Sentimiento peruano</span>, versión original de Carlos Ayala
										</li>
										<li><span className="point">.</span><span className="text">Hip Hop / Rap Instrumental Beat - Canon in D</span>, por Johann Pachelbel</li>
										<li><span className="point">.</span><span className="text">Amanecer Andino</span>, por Daniel Alomía Robles</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal className="modal-cysII" show={show13} onHide={handleClose13}>
					<Modal.Body className="modal-cys-bodyII" role="button" tabIndex="0" onClick={handleClose13}>
						<div className="text-container">
							<div className="text-subcontainer">
								<h3>Miguel Aguirre</h3>
								<p>
									Gestor cultural en iniciativas artísticas y culturales colectivas. Director de Amaru Casa Cultural durante tres años, desde el 2017. Realizador audiovisual
									independiente, operador de dron. Con estudios profesionales en periodismo y
									publicidad. Bombero voluntario de la Compañía de Bomberos San Isidro N°100.<br></br>
									<span>Miguel Aguirre fue ex alumno del maestro Enrique Bustamante en el colegio peruano-francés
										y desde entonces conserva su admiración, inclusión en proyectos artísticos y su amistad.</span>
								</p>
							</div>
						</div>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483203988?title=false&portrait=false&byline=false" width="560" height="315" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>


				<Modal className="modal-cysII" show={show14} onHide={handleClose14}>
					<Modal.Body className="modal-cys-bodyII" role="button" tabIndex="0" onClick={handleClose14}>
						<div className="text-container">
							<div className="text-subcontainer">
								<h3>LILIANA GALVÁN</h3>
								<p>
									Psicóloga educacional. Asesora, especialista en investigación y ejecución de
									proyectos. Directora de Integrarte. Directora de Calidad Educativa en la Universidad
									Peruana de Ciencias Aplicadas - UPC durante 17 años, desde 1996. Participó del diseño de
									diferentes programas académicos y fundó la Facultad de Psicología, donde fue Decana.<br></br>
									<span>
										Liliana Galván ha trabajado con Enrique Bustamante
										en diversos proyectos artísticos y educativos.
									</span>
								</p>
							</div>
						</div>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483202519?title=false&portrait=false&byline=false" width="173" height="315" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>



				<Modal className="modal-cysII" show={show15} onHide={handleClose15}>
					<Modal.Body className="modal-cys-bodyII" role="button" tabIndex="0" onClick={handleClose15}>
						<div className="text-container">
							<div className="text-subcontainer">
								<h3>MILTON ROMÁN</h3>
								<p>
									Licenciado en Docencia en Arte. Con estudios de maestría
									en Historia del Arte. Consultor Creativo y Publicista.
									Artista Pintor egresado de la Escuela Superior de Bellas Artes Macedonio de la Torre en Trujillo.<br></br>
									<span>
										Milton Román ha realizado la curaduría de varias de las exposiciones artísticas que
										ha presentado el maestro Enrique Bustamante, entre otros proyectos.
									</span>
								</p>
							</div>
						</div>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483204614?title=false&portrait=false&byline=false" width="173" height="315" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>
				<Modal className="modal-cysII" show={show16} onHide={handleClose16}>
					<Modal.Body className="modal-cys-bodyII" role="button" tabIndex="0" onClick={handleClose16}>
						<div className="text-container">
							<div className="text-subcontainer">
								<h3>MANUELA CASTAÑEDA</h3>
								<p>
									Profesora de Lengua y Literatura. Con estudios de maestría en Enseñanza en el
									Nivel Universitario, de especialización en Problemas de Aprendizaje. Diplomado en
									Audición y Lenguaje para niños sordos e hipoacúsicos. Especialización y
									experiencia en Redacción y Asesoría de Tesis.<br></br>
									<span>
										Manuela Castañeda Jara ha realizado la selección de poesía y corrección de estilo
										para los poemarios de Enrique Bustamante.
									</span>
								</p>
							</div>
						</div>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483203245?title=false&portrait=false&byline=false" width="173" height="315" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>
				<Modal className="modal-cysII" show={show17} onHide={handleClose17}>
					<Modal.Body className="modal-cys-bodyII" role="button" tabIndex="0" onClick={handleClose17}>
						<div className="text-container">
							<div className="text-subcontainer">
								<h3>EMILIO CAZORLA</h3>
								<p>
									Diseñador Gráfico egresado de la Facultad de Arte y Diseño de la Pontificia
									Universidad Católica del Perú - PUCP. Profesor de Diseño del Instituto Toulouse
									Lautrec desde hace más de 15 años.<br></br>
									<span>
										Emilio Cazorla y Enrique Bustamante afianzaron un a profunda amistad. El 2017, Cazorla
										le rindió homenaje al maestro pintando una ilustraciónen técnica mixta donde se puede apreciar
										el retrato del artista y alrededor algunos docentes colegas, como sus discípulos. A modo de alegoría, la obra se llama
										“La última escena”.
									</span>
								</p>
							</div>
						</div>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483201528?title=false&portrait=false&byline=false" width="173" height="315" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>

				<Modal className="modal-cysIII" show={show42} onHide={handleClose42}>
					<Modal.Body className="modal-cys-bodyIII" role="button" tabIndex="0" onClick={handleClose42}>
						<div className="iframe-container">
							<iframe title="color y sentimiento" src="https://player.vimeo.com/video/483200175?title=false&portrait=false&byline=false" width="231" height="400" frameBorder="0"></iframe>
						</div>
					</Modal.Body>
				</Modal>




				<Modal className="modal-cys" show={show1} onHide={handleClose1}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose1}>
						<div>
							<img alt="color y sentimiento imagen arte 1" src={Artm1}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Azul en Primavera</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show2} onHide={handleClose2}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose2}>
						<div>
							<img alt="color y sentimiento imagen arte 2" src={Artm2}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>El Llamado</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show3} onHide={handleClose3}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose3}>
						<div>
							<img alt="color y sentimiento imagen arte 3" src={Artm3}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>El Encuentro</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show4} onHide={handleClose4}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose4}>
						<div>
							<img alt="color y sentimiento imagen arte 4" src={Artm4}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>El Jardín de Nicolás</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show5} onHide={handleClose5}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose5}>
						<div>
							<img alt="color y sentimiento imagen arte 5" src={Artm5}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Primavera en Antioquía</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show6} onHide={handleClose6}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose6}>
						<div>
							<img alt="color y sentimiento imagen arte 6" src={Artm6}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Contemplación</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show7} onHide={handleClose7}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose7}>
						<div>
							<img alt="color y sentimiento imagen arte 7" src={Artm7}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Paraíso en Chancay</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show8} onHide={handleClose8}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose8}>
						<div>
							<img alt="color y sentimiento imagen arte 8" src={Artm8}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Dulce aroma del campo</h2>
						<h2>Serie Flores - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show9} onHide={handleClose9}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose9}>
						<div>
							<img alt="color y sentimiento imagen arte 9" src={Artm9}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Un lugar de amor bajo el sol</h2>
						<h2>Serie Palomas - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show10} onHide={handleClose10}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose10}>
						<div>
							<img alt="color y sentimiento imagen arte 10" src={Artm10}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Entre flores te fuiste</h2>
						<h2>Serie Flores - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show11} onHide={handleClose11}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose11}>
						<div>
							<img alt="color y sentimiento imagen arte 11" src={Artm11}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Flores circulares</h2>
						<h2>Serie Flores - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show12} onHide={handleClose12}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose12}>
						<div>
							<img alt="color y sentimiento imagen arte 12" src={Artm12}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Las primas</h2>
						<h2>Serie Warmis - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show18} onHide={handleClose18}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose18}>
						<div>
							<img alt="color y sentimiento imagen arte 13" src={Artm13}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Amigas de altura</h2>
						<h2>Serie Warmis - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show19} onHide={handleClose19}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose19}>
						<div>
							<img alt="color y sentimiento imagen arte 14" src={Artm14}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Fragmento de Rendida a tus pies</h2>
						<h2>Serie Warmis - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show20} onHide={handleClose20}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose20}>
						<div>
							<img alt="color y sentimiento imagen arte 15" src={Artm15}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Vicuñas</h2>
						<h2><span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show21} onHide={handleClose21}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose21}>
						<div>
							<img alt="color y sentimiento imagen arte 16" src={Artm16}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Fragmento de El paraíso de Enrique</h2>
						<h2>Serie Paisajes imperdibles - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show22} onHide={handleClose22}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose22}>
						<div>
							<img alt="color y sentimiento imagen arte 17" src={Artm17}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Homenaje a Vincent Van Gogh</h2>
						<h2>Serie Paisajes imperdibles - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show23} onHide={handleClose23}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose23}>
						<div>
							<img alt="color y sentimiento imagen arte 18" src={Artm18}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Regiones de mi Perú</h2>
						<h2>Serie Paisajes imperdibles - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show24} onHide={handleClose24}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose24}>
						<div>
							<img alt="color y sentimiento imagen arte 19" src={Artm19}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Salvaje encanto</h2>
						<h2>Serie Paisajes imperdibles - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show25} onHide={handleClose25}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose25}>
						<div>
							<img alt="color y sentimiento imagen arte 20" src={Artm20}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Inmaculada</h2>
						<h2>De lo humano a lo divino - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show26} onHide={handleClose26}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose26}>
						<div>
							<img alt="color y sentimiento imagen arte 21" src={Artm21}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Autorretrato</h2>
						<h2><span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show27} onHide={handleClose27}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose27}>
						<div>
							<img alt="color y sentimiento imagen arte 22" src={Artm22}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Cerro el Agustino</h2>
						<h2>Serie Perú - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show28} onHide={handleClose28}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose28}>
						<div>
							<img alt="color y sentimiento imagen arte 23" src={Artm23}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Villa El Salvador</h2>
						<h2>Serie Perú - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show29} onHide={handleClose29}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose29}>
						<div>
							<img alt="color y sentimiento imagen arte 24" src={Artm24}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Cuando el sol sale para todos</h2>
						<h2>Serie Selva - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show30} onHide={handleClose30}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose30}>
						<div>
							<img alt="color y sentimiento imagen arte 25" src={Artm25}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>El otro jardín</h2>
						<h2>Serie Selva - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show31} onHide={handleClose31}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose31}>
						<div>
							<img alt="color y sentimiento imagen arte 26" src={Artm26}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Selva II</h2>
						<h2>Serie Selva - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show32} onHide={handleClose32}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose32}>
						<div>
							<img alt="color y sentimiento imagen arte 27" src={Artm27}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Cruz del camino</h2>
						<h2>Serie paisajes - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show33} onHide={handleClose33}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose33}>
						<div>
							<img alt="color y sentimiento imagen arte 28" src={Artm28}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>En las alturas</h2>
						<h2>Serie paisajes - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show34} onHide={handleClose34}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose34}>
						<div>
							<img alt="color y sentimiento imagen arte 29" src={Artm29}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Oración nocturna</h2>
						<h2>Serie paisajes - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show35} onHide={handleClose35}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose35}>
						<div>
							<img alt="color y sentimiento imagen arte 30" src={Artm30}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Coctel de plátano</h2>
						<h2>Serie Fragmentus - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show36} onHide={handleClose36}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose36}>
						<div>
							<img alt="color y sentimiento imagen arte 31" src={Artm31}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Fragmentus1</h2>
						<h2>Serie Fragmentus - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show37} onHide={handleClose37}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose37}>
						<div>
							<img alt="color y sentimiento imagen arte 32" src={Artm32}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Pecera nutrida</h2>
						<h2>Serie Fragmentus - <span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show38} onHide={handleClose38}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose38}>
						<div>
							<img alt="color y sentimiento imagen arte 33" src={Artm33}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Perú, encuentro de gaviotas</h2>
						<h2><span>Acrílico sobre tela</span></h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show39} onHide={handleClose39}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose39}>
						<div>
							<img alt="color y sentimiento imagen arte 34" src={Artm34}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Vitrofusión, cerámica intervenida y objetos pintados</h2>
					</Modal.Footer>
				</Modal>


				<Modal className="modal-cys" show={show40} onHide={handleClose40}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose40}>
						<div>
							<img alt="color y sentimiento imagen arte 35" src={Artm35}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Jarrones de cerámica intervenidos</h2>
					</Modal.Footer>
				</Modal>

				<Modal className="modal-cys" show={show41} onHide={handleClose41}>
					<Modal.Body className="modal-cys-body" role="button" tabIndex="0" onClick={handleClose41}>
						<div>
							<img alt="color y sentimiento imagen arte 36" src={Artm36}></img>
						</div>
					</Modal.Body>
					<Modal.Footer className="modal-cys-footer">
						<h2>Retablo ̈"Yo soy de exportación"</h2>
						<h2><span>Autorretrato de Enrique Bustamante</span></h2>
					</Modal.Footer>
				</Modal>
			</div>
		</Layout>
	)
}

export default Colorsentimiento


function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
}